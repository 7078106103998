@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

.App_parentContainer__1BpSx {
  background: #0074D9; }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

.AppContent_warning__2CSp4 {
  display: none;
  height: 100vh; }

.AppContent_layoutParent__1Xhq6 .AppContent_sideBar__1KHA0 {
  position: absolute;
  right: 0;
  top: 0; }

.AppContent_layoutParent__1Xhq6 .AppContent_bottomBar__1Dppx {
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.AppContent_layoutParent__1Xhq6 .AppContent_activeLogo__19wYe {
  position: fixed;
  left: 5px;
  top: 5px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_activeLogo__19wYe img {
    width: 150px; }

.AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_credits__nAgSo {
    letter-spacing: 1.5px;
    position: fixed;
    left: 5px;
    top: 3px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_logo__3FL2l {
    width: 500px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_subtitle__gvNGQ {
    font-size: 70px;
    color: #fff; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_rules__1WsOh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_rules__1WsOh span {
      font-size: 40px;
      margin-bottom: 10px;
      color: #fff; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_disclaimer__goenw {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_disclaimer__goenw span {
      font-size: 20px;
      margin-bottom: 10px;
      color: #fff; }
    .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_disclaimer__goenw .AppContent_icons__1zhrz img {
      width: 24px; }

.AppContent_layoutParent__1Xhq6 .AppContent_subjectWrapper__3jH5L {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 98vh; }
  .AppContent_layoutParent__1Xhq6 .AppContent_subjectWrapper__3jH5L .AppContent_name__3mea1 {
    color: #fff;
    font-size: 24px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_subjectWrapper__3jH5L img {
    width: 450px;
    cursor: url("https://img.icons8.com/officel/80/000000/hand.png"), auto; }

.AppContent_hit__3LXWL {
  -webkit-animation: AppContent_shake__xLx76 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: AppContent_shake__xLx76 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.AppContent_gameOverParent__8S3xc {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff; }
  .AppContent_gameOverParent__8S3xc .AppContent_title__12DAI {
    font-weight: 700;
    font-size: 60px;
    letter-spacing: 4.8px; }
  .AppContent_gameOverParent__8S3xc .AppContent_subtitle__gvNGQ {
    font-size: 20px;
    letter-spacing: 0.8px; }
  .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 {
    margin-top: 20px; }
    .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 span {
      padding: 20px;
      font-size: 40px;
      border: 1px solid #eaeaea; }
      .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 span:first-child {
        background: #FF851B;
        margin-right: 5px; }
      .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 span:nth-child(2) {
        background: #e50404; }
  .AppContent_gameOverParent__8S3xc .AppContent_coolStuffHeader__2fp2C {
    margin-top: 20px;
    font-size: 20px; }
  .AppContent_gameOverParent__8S3xc .AppContent_coolStuff__3Qgmo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px; }
    .AppContent_gameOverParent__8S3xc .AppContent_coolStuff__3Qgmo img.AppContent_icon__1W843 {
      width: 45px;
      height: 45px; }
  .AppContent_gameOverParent__8S3xc .AppContent_button__25WTB {
    margin-right: 5px;
    width: 100px;
    height: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #000; }
    .AppContent_gameOverParent__8S3xc .AppContent_button__25WTB span {
      text-decoration: overline;
      font-size: 45px;
      color: #000;
      font-weight: 900;
      font-family: monospace;
      color: #0074D9; }
    .AppContent_gameOverParent__8S3xc .AppContent_button__25WTB:hover {
      background: #e50404; }

@-webkit-keyframes AppContent_shake__xLx76 {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes AppContent_shake__xLx76 {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@media screen and (max-width: 768px) {
  .AppContent_layoutParent__1Xhq6 .AppContent_sideBar__1KHA0 {
    position: relative;
    margin: auto; }
  .AppContent_layoutParent__1Xhq6 .AppContent_activeLogo__19wYe {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center; }
  .AppContent_layoutParent__1Xhq6 .AppContent_bottomBar__1Dppx {
    top: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_credits__nAgSo {
    letter-spacing: 1.5px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 3px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_subtitle__gvNGQ {
    font-size: 30px;
    color: #fff;
    text-align: center; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_rules__1WsOh span {
    font-size: 25px;
    margin-bottom: 10px;
    color: #fff;
    text-align: center; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_disclaimer__goenw {
    text-align: center; }
  .AppContent_layoutParent__1Xhq6 .AppContent_content__OUWyS .AppContent_logo__3FL2l {
    width: 200px;
    margin-top: 30px; }
  .AppContent_layoutParent__1Xhq6 .AppContent_subjectWrapper__3jH5L {
    justify-content: unset;
    padding-top: 20px;
    height: 60vh; }
    .AppContent_layoutParent__1Xhq6 .AppContent_subjectWrapper__3jH5L img {
      width: 350px; }
  .AppContent_warning__2CSp4 {
    display: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 30px;
    text-align: center;
    font-size: 50px; }
  .AppContent_gameOverParent__8S3xc {
    text-align: center; }
    .AppContent_gameOverParent__8S3xc .AppContent_subtitle__gvNGQ {
      padding: 50px; }
    .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .AppContent_gameOverParent__8S3xc .AppContent_actionBtns__2pwR8 span {
        margin-bottom: 20px; }
    .AppContent_gameOverParent__8S3xc .AppContent_button__25WTB {
      width: 70px;
      height: 70px;
      margin-bottom: 10px; } }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

.FaceChooser_wrapper__2csgA {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border-left: 2px solid #eaeaea2e;
  border-bottom: 2px solid #eaeaea2e;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }
  .FaceChooser_wrapper__2csgA .FaceChooser_facePane__3K7s8 {
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .FaceChooser_wrapper__2csgA .FaceChooser_facePane__3K7s8:hover {
      background: #fff; }
    .FaceChooser_wrapper__2csgA .FaceChooser_facePane__3K7s8 img {
      width: 100px; }

@media screen and (max-width: 768px) {
  .FaceChooser_wrapper__2csgA {
    flex-direction: row; } }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

* {
  box-sizing: border-box; }

.ProgressBar_progress__3EPNb {
  background-color: #d8d8d8;
  border: 3px solid #000;
  position: relative;
  height: 30px;
  width: 200px;
  font-weight: 800; }

.ProgressBar_progressDone__2AAio {
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  -webkit-transition: 1s ease 0.3s;
  transition: 1s ease 0.3s; }

.ProgressBar_blue__2iO3q {
  background: -webkit-gradient(linear, right top, left top, from(#70b0f2), to(#3f8edf));
  background: -webkit-linear-gradient(right, #70b0f2, #3f8edf);
  background: linear-gradient(to left, #70b0f2, #3f8edf); }

.ProgressBar_red__2j9Ip {
  background: -webkit-gradient(linear, right top, left top, from(#e50404), to(#b60404));
  background: -webkit-linear-gradient(right, #e50404, #b60404);
  background: linear-gradient(to left, #e50404, #b60404); }

.ProgressBar_gold__2GDb- {
  background: -webkit-gradient(linear, right top, left top, from(#ffc700), to(#d9ae18));
  background: -webkit-linear-gradient(right, #ffc700, #d9ae18);
  background: linear-gradient(to left, #ffc700, #d9ae18); }

.ProgressBar_green__2n2bx {
  background: -webkit-gradient(linear, right top, left top, from(#78f270), to(#2ead26));
  background: -webkit-linear-gradient(right, #78f270, #2ead26);
  background: linear-gradient(to left, #78f270, #2ead26); }

@media screen and (max-width: 768px) {
  .ProgressBar_progress__3EPNb {
    width: 160px; } }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

.Docker_wrapper__3jSNj {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  border: none;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0px 3px 12px 3px #00000014;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: #FF851B;
  color: #fff;
  text-align: center; }
  .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg .Docker_dockChild__4Yfxe {
      margin: 10px; }
      .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg .Docker_dockChild__4Yfxe:first-child {
        border-right: 1px solid #eaeaea;
        padding-right: 15px; }

@media screen and (max-width: 768px) {
  .Docker_wrapper__3jSNj {
    width: 100%;
    height: auto; }
    .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg {
      flex-direction: column-reverse; }
      .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg .Docker_dockChild__4Yfxe {
        margin: 2px; }
        .Docker_wrapper__3jSNj .Docker_childrenHolder__2znMg .Docker_dockChild__4Yfxe:first-child {
          border-right: none;
          padding-right: 0px; } }

* {
  font-family: 'Bangers', cursive;
  cursor: url("https://img.icons8.com/fluent/96/000000/hand-cursor--v2.png"), auto; }

body {
  background: #e50404;
  touch-action: manipulation; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    color: #e50404; }

* {
  box-sizing: border-box; }

.Button_button__3GgJ3 {
  display: flex; }

